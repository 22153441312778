<template >
    <!-- About Section -->
    <div class="index-about">
      <div class="index-main_about">
        <img src="@/assets/img/kuanteklogotrans.png" alt="">
          <div class="index-all_text">
              <h1>WHAT WE DO</h1>
              <p>Kuantek offers high-end engineering solutions collaborating high expertise R&D engineers. We focus on partnerships specialized in defense,energy and electronics sectors. 

                Kuantek engineers have expertise in all aspects of product development including project management, concept development, and design in software engineering parallel to circuit design.
                
                Project management teams master system integration, testing and validation, prototyping, production requirements, quality assurance, certification in an agile methodology.
              </p>
              <div class="btn">
                  <a href="contact.html">
                  <button type="button">SALES</button></a> 
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import Card from '../components/Card';

 export default {
  components: {
    Card
  }
 }

</script>

<style>
.index-about{
	height: 70vh;
	width:100%;
	background: white;
	display: flex;
	justify-content:center ;
  }
  .index-main_about{
	width: 1290px;
	max-width: 90%;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
  }
  .index-about .index-main_about img{
	width: 380px;
	max-width: 100%;
	height: auto;
	padding: 0 10px;
  }
  .index-all_text{
	width: 600px;
	max-width: 100%;
	padding: 0 10px;
  }
  .index-all_text h1{
	font-size: 65px;
	color: #777777;
	font-weight: 700;
	margin-bottom: 20px;
  }
  .index-all_text h4{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 18px;
	color: black;
	letter-spacing: 1px;
	font-weight:400;
	margin-bottom: 10px;
	text-align: center;
  }
  .index-all_text p{
	font-family: Arial, Helvetica, sans-serif;
	font-size: 16px;
	color: #777777;
	line-height: 30px;
	margin-bottom: 35px;
  }
  .btn button{
	background: white;
	padding: 20px 32px;
	font-size: 16px;
	font-weight: bold;
	color: #111111;
	border: none;
	outline: none;
	box-shadow:  0px 16px 32px 0px rgba(0 0 0/ 6%);
  }
  .btn button:hover{
	background-color: #be020f;
	color: white;
	transition: .3s;
	cursor: pointer;
  }
  @media screen and (max-width:1250px){
	.index-about{
	  width: 100%;
	  height: auto;
	  padding: 60px 0;
	}
	.index-all_text{
	  text-align: center;
	  margin-top: 40px;
	}
	.index-about .index-main_about img{
	  margin-bottom: 35px;
	  width: 350px;
	  align-items: center;
	}
  }
  @media screen and (max-width:650px){
	.index-about .index-main_about img{
	  margin-bottom: 35px;
	  width: 350px;
	  align-items: center;
	}
	.index-all_text h1{
	  font-size:45px;
	  margin-bottom: 20px;
	}
  }
  
  .index-all_text ul {
	  list-style: none;
  }
  
  .index-all_text ul li {
	  position: relative;
	  line-height: 40px;
  }
  
  .index-all_text li:before {
	  content: "•";
	  position: absolute;
	  left: -1em;
	  color: black;
  }

</style>

