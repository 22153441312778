<template> 
  <header class="header">
    <div class="container">
      <div class="row v-center">
        
		<div class="header-item item-left">
          <div class="logo">
			<router-link to="/"><img src="../assets/img/kuanteklogo.png" alt=""></router-link>
          </div>
        </div>

        <!-- menu start here -->
        <div class="header-item item-center">
          <nav class="menu">
            <div class="mobile-menu-head">
              <div class="go-back">&times;</div>
              <div class="current-menu-title"></div>
              <div class="mobile-menu-close">&times;</div>
            </div>
            <ul class="menu-main">
              <li class="menu-item-has-children">
                <a href="#">Products</a>
                <div class="sub-menu mega-menu mega-menu-column-4">
					<div class="list-item">
                    <h4 class="title">IR CAMERA</h4>
                    <ul>
                      <li><router-link to="/LWIR-Camera-Core">LongWave Infrared Imaging Core</router-link></li>
                    </ul>
                  </div>
                  <div class="list-item">
                    <h4 class="title">IP CORE</h4>
                    <ul>
                      <li><router-link to="/jesd204b-ip-core">JESD204B</router-link></li>
                      <li><router-link to="/can-canfd-ip-core">CAN/CAN FD IP CORE</router-link></li>
                      <li><router-link to="/ethernet-ip-core">Ethernet IP CORE</router-link></li>
                      <li><router-link to="/surround-vision-system">Surround Vision System</router-link></li>
                      <li><router-link to="/thermal-vision-isp">Thermal Vision ISP</router-link></li>
                      <li><router-link to="/day-vision-isp">Day Vision ISP</router-link></li>
                    </ul>
                  </div>

                  <div class="list-item">
                    <h4 class="title">FPGA SoM & Development Kit</h4>
                    <ul>
                      <li><router-link to="/Nyks+KFM-E-T120SoM">Nyks+ KFM-E-T120 SoM</router-link></li>
                      <li><router-link to="/Nyks+KFM-E-T20SoM">Nyks KFM-E-T20 SoM</router-link></li>
                      <li><router-link to="/Nyks+KFM-E-Ti35SoM">Nyks KFM-E-Ti35 SoM</router-link></li>
					  <li><router-link to="/TriPi-FPGA-Development-Board">TriPi FPGA Development Board </router-link></li>

                    </ul>
                  </div>

                  <div class="list-item">
                    <h4 class="title">PRIC OFFER</h4>
                    <ul>
                      <li><router-link to="/contact">Contact Us</router-link></li>
                    </ul>
                  </div>

                </div>
              </li>
              <li class="menu-item-has-children">
                <a href="#">Services</a>
                <div class="sub-menu mega-menu mega-menu-column-4">
                  <div class="list-item">
                      <router-link to="/training"><h4 class="title">TRAINING SERVICES</h4></router-link>
                    <ul>
                      <li><router-link to="#">Basic FPGA Tutorial</router-link></li>
                      <li><router-link to="#">Advanced FPGA Training</router-link></li>
                    </ul>
                  </div>
                  <div class="list-item">
                    <h4 class="title">CONSULTANCY SERVICES</h4>
                    <ul>
                      <li><router-link to="#">System Level Architectural Design</router-link></li>
                      <li><router-link to="#">Digital and Analog Circuit Design and Simulation</router-link></li>
                      <li><router-link to="#">Schematic and Multilayer PCB Design</router-link></li>
                      <li><router-link to="#">FPGA and DSP Based Embedded Software/Hardware Development</router-link></li>
                      <li><router-link to="#">Embedded Linux and RTOS Experience</router-link></li>
                      <li><router-link to="#">Telecommunications and Network Experience</router-link></li>

                    </ul>

                  </div>
                  <div class="list-item">
                    <h4 class="title">R&D SERVICES</h4>
                    <ul>
                      <li><router-link to="#">Electronic Circuit Design</router-link></li>
                      <li><router-link to="#">Image processing – R & D SERVICE</router-link></li>
                      <li><router-link to="#">Embedded Software and Hardware Design</router-link></li>

                    </ul>
                  </div>

                  <div class="list-item">
                    <h4 class="title">PRICE OFFER</h4>
                    <ul>
                      <li><router-link to="/contact">Contact Us</router-link></li>
                    </ul>
                  </div>
                </div>
              </li>

            <li class="menu-item-has-children">
                <router-link to="#">Efinix </router-link>
                <div class="sub-menu mega-menu mega-menu-column-4">
                  <div class="list-item">
                    <h4 class="title">DEVELOPMENT KIT</h4>
                    <ul>
                      <li><router-link to="titanium-ti60-dev">Titanium Ti60 F225 Development Kit</router-link></li>
                      <li><router-link to="trion-t120-bga576">Trion T120 BGA576 Development Kit</router-link></li>
                      <li><router-link to="trion-t120-bga324">Trion T120 BGA324 Development Kit</router-link></li>
                      <li><router-link to="trion-t120-bga256">Trion T20 BGA256 Development Kit</router-link></li>
                      <li><router-link to="trion-t20">Trion T20 MIPI Development Kit</router-link></li>
                      <li><router-link to="trion-t8">Trion T8 BGA81 Development Kit</router-link></li>
                      <li><router-link to="/xyloni">XYLONI Development Kit</router-link></li>
                    </ul>
                  </div>
                  <div class="list-item">
                    <h4 class="title">PRICE OFFER</h4>
                    <ul>
                      <li><router-link to="/contact">Contact Us</router-link></li>

                    </ul>
                  </div>

                </div>
              <li class="menu-item-has-children">
                <router-link to="#">Solutions </router-link>
                <div class="sub-menu single-column-menu">
                  <ul>
                    <li><router-link to="">ENERGY MONITORING MANAGEMENT SYSTEMS</router-link></li>
                    <li><router-link to="">NETWORK SOLUTIONS</router-link></li>
                  </ul>
                </div>
              </li>
              <li class="menu-item-has-children">
                <router-link to="#">Corporate </router-link>
                <div class="sub-menu single-column-menu">
                  <ul>
                    <li><router-link to="/about">ABOUT </router-link></li>
					<li><router-link to="/news">NEWS</router-link></li>
					<li><router-link to="/document">DOCUMENT</router-link></li>
                  </ul>
                </div>
              </li>
			  <li>
                <router-link to="/blog">Blog</router-link>
              </li>

              <li class="menu-item-has-children">
                <router-link to="#">Distributorships</router-link>
                <div class="sub-menu single-column-menu">
                  <ul>
                    <li><a href="https://www.efinixinc.com/index.html" target="_blank">EFINIX</a></li>
                    <li><a href="https://www.engicam.com/" target="_blank">ENGICAM</a></li>
                    <li><a href="https://www.testonica.com/" target="_blank">TESTONICA</a></li>
                    <li><a href="https://www.cactus-tech.com/" target="_blank">CACTUS TECHNOLOGIES</a></li>
                    <li><a href="https://noesis-tech.com/" target="_blank">NEOSIS TECHNOLOGIES</a></li>
                    <li><a href="https://www.alma-technologies.com/" target="_blank">ALMA TECHNOLOGIES</a></li>
                    <li><a href="https://www.inavitas.com/?lang=tr" target="_blank">INAVITAS</a></li>
                    <li><a href="http://intheca.com/#/" target="_blank">INTHECA</a></li>
                    <li><a href="https://www.tayftech.com/" target="_blank">TAYFTECH</a></li>
                  </ul>
                </div>
              </li>

              <li>
                <router-link to="/contact">Contact Us</router-link>
              </li>
            </ul>
          </nav>
        </div>
        <!-- menu end here -->
        <div class="header-item item-right">
          <!-- mobile menu trigger -->
          <div class="mobile-menu-trigger">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import router from '@/router';
import { RouterLink } from 'vue-router';

export default {
  mounted() {
    const menu = document.querySelector('.menu');
    const menuMain = menu.querySelector('.menu-main');
    const goBack = menu.querySelector('.go-back');
    const menuTrigger = document.querySelector('.mobile-menu-trigger');
    const closeMenu = menu.querySelector('.mobile-menu-close');
    let subMenu;

    menuMain.addEventListener('click', (e) => {
      if (!menu.classList.contains('active')) {
        return;
      }
      if (e.target.closest('.menu-item-has-children')) {
        const hasChildren = e.target.closest('.menu-item-has-children');
        showSubMenu(hasChildren);
      }
    });

    goBack.addEventListener('click', () => {
      hideSubMenu();
    });

    menuTrigger.addEventListener('click', () => {
      toggleMenu();
    });

    closeMenu.addEventListener('click', () => {
      toggleMenu();
    });

    function toggleMenu() {
      menu.classList.toggle('active');
      // document.querySelector('.menu-overlay').classList.toggle('active');
    }

    function showSubMenu(hasChildren) {
      subMenu = hasChildren.querySelector('.sub-menu');
      subMenu.classList.add('active');
      subMenu.style.animation = 'slideLeft 0.5s ease forwards';
      const menuTitle = hasChildren.querySelector('a').textContent;
      menu.querySelector('.current-menu-title').innerHTML = menuTitle;
      menu.querySelector('.mobile-menu-head').classList.add('active');
    }

    function hideSubMenu() {
      subMenu.style.animation = 'slideRight 0.5s ease forwards';
      setTimeout(() => {
        subMenu.classList.remove('active');
      }, 300);
      menu.querySelector('.current-menu-title').innerHTML = '';
      menu.querySelector('.mobile-menu-head').classList.remove('active');
    }

    window.onresize = function () {
      if (this.innerWidth > 991) {
        if (menu.classList.contains('active')) {
          toggleMenu();
        }
      }
    };
  },
  components: { router, RouterLink },
};
</script>

<style scoped>
.row{
	display: flex;
	flex-wrap: wrap;
}

/* header */
.header{
	display: block;
	width: 100%;
	position: relative;
	z-index: 99;
	padding:15px;
}
.header .item-left{
	flex:0 0 20%;
	display: flex;
	flex-wrap: wrap;
}
.header .logo img{
	width:70%;

}
.header .item-center{
	flex:0 0 76%;
}
.header .item-right{
	flex:0 0 17%;
	display: flex;
	justify-content: flex-end;
}
.header .item-right a{
    text-decoration: none;
    font-size: 15px;
    color:#555555;
    display: inline-block;
    margin-left: 10px;
    transition: color 0.3s ease;
}
.header .menu > ul > li{
	display: inline-block;
	line-height: 50px;
	margin-left: 40px;
}
.header .menu > ul > li > a{
	font-size: 13px;
	font-weight: bold;
	color:#000000;
	text-transform: capitalize;
	transition: color 0.3s ease;
	margin: auto;
}
.header .menu > ul > li .sub-menu{
	position: absolute;
	z-index: 500;
	background-color:#ffffff;
	box-shadow: -2px 2px 70px -25px rgba(0,0,0,0.3);
	padding: 20px 30px;
	transition: all 0.5s ease;
	margin-top:25px;
	opacity:0;
	visibility: hidden;
}
@media(max-width: 992px){
.header .logo{
	width:250px;
}
}
@media(min-width: 992px){


.header .menu > ul > li.menu-item-has-children:hover .sub-menu{
	margin-top: 0;
	visibility: visible;
	opacity: 1;
}
}
.header .menu > ul > li .sub-menu > ul > li{
	line-height: 1;
}
.header .menu > ul > li .sub-menu > ul > li > a{
	display: inline-block;
	padding: 10px 0;
	font-size: 13px;
	color: #555555;
	transition: color 0.3s ease;
	text-decoration: none;
	text-transform: capitalize;
}
.header .menu > ul > li .single-column-menu{
	min-width: 280px;
	max-width: 350px;
	font-weight: bold;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li{
	line-height: 1;
	display: block;

}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a {
	padding:10px 0;
	display: inline-block;
	font-size: 13px;
	font-weight: bold;
	color:#555555;
	transition: color 0.3s ease;
	
}
.header .menu > ul > li .sub-menu.mega-menu{
    left: 50%;
    transform: translateX(-50%);
}

.header .menu > ul > li .sub-menu.mega-menu-column-4{
max-width: 1300px;
width: 100%;
display: flex;
flex-wrap: wrap;
padding:20px 15px;

}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
flex:0 0 25%;
padding:0 15px;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item .title{
	font-size: 16px;
	color:#ED2330;
	font-weight: bold;
	line-height: 1;
	padding:10px 0;
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
	text-align: center;
	
}
.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
	max-width: 100%;
	width: 100%;
	vertical-align: middle;
	margin-top: 10px;
}
.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a:hover,
.header .menu > ul > li .sub-menu > ul > li > a:hover,
.header .item-right a:hover,
.header .menu > ul > li:hover > a{
	text-decoration: none !important;
	cursor:pointer ;
	color:#ED2330;
}
/* banner section */

.main_banner{
background-image: url('../assets/img/bgHero.jpg');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 600px;
  display: block;

}
@media(max-width:1350px){
	.main_banner{
	width: 100%;
    height: auto;
    padding: 100px 0;
	}
}

.mobile-menu-head,
.mobile-menu-trigger{
	display: none;
}

/*responsive*/
@media(max-width: 1000px){
	.header .item-center{
		order:3;
		flex:0 0 100%;
	}
	.header .item-left,
	.header .item-right{
		flex:0 0 auto;
	}
	.v-center{
		justify-content: space-between;
	}
	.header .mobile-menu-trigger{
		display: flex;
		height: 30px;
		width: 30px;
		margin-left: 15px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
	}
	.header .mobile-menu-trigger span{
		display: block;
		height: 2px;
		background-color: #333333;
		width: 24px;
		position: relative;
	}
	.header .mobile-menu-trigger span:before,
	.header .mobile-menu-trigger span:after{
		content: '';
		position: absolute;
		left:0;
		width: 100%;
		height: 100%;
		background-color: #333333;
	}
	.header .mobile-menu-trigger span:before{
		top:-6px;
	}
	.header .mobile-menu-trigger span:after{
		top:6px;
	}
	.header .item-right{
		align-items: center;
	}

	.header .menu{
		position: fixed;
		width: 360px;
		background-color:#ffffff;
		left:0;
		top:0;
		height: 100%;
		overflow: hidden;
		transform: translate(-100%);
		transition: all 0.5s ease;
		z-index: 1099;
	}
	.header .menu.active{
	transform: translate(0%);
	}
	.header .menu > ul > li{
		line-height: 1;
		margin:0;
		display: block;
	}
	.header .menu > ul > li > a{
		line-height: 50px;
		height: 50px;
		padding:0 50px 0 15px;
		display: block;
		border-bottom: 1px solid rgba(0,0,0,0.1);

	}
	.header .menu > ul > li > a i{
		position: absolute;
		height: 50px;
		width: 50px;
		top:0;
		right: 0;
		text-align: center;
		line-height: 50px;
		transform: rotate(-90deg);
	}
	.header .menu .mobile-menu-head{
		display: flex;
		height: 50px;
		border-bottom: 1px solid rgba(0,0,0,0.1);
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 501;
		position: sticky;
		background-color: #ffffff;
		top:0;
	}
	.header .menu .mobile-menu-head .go-back{
		height: 50px;
		width: 50px;
		border-right: 1px solid rgba(0,0,0,0.1);
		cursor: pointer;
		line-height: 50px;
		text-align: center;
		color:black;
		font-size: 16px;
		display: none;
	}
	.header .menu .mobile-menu-head.active .go-back{
		display: block;
	}
	.header .menu .mobile-menu-head .current-menu-title{
		font-size: 15px;
		font-weight: 500;
		color:#000000;
	}
	.header .menu .mobile-menu-head .mobile-menu-close{
	height: 50px;
	width: 50px;
	border-left: 1px solid rgba(0,0,0,0.1);
	cursor: pointer;
	line-height: 50px;
	text-align: center;
	color:#000000;
	font-size: 25px;
	}
	.header .menu .menu-main{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.header .menu > ul > li .sub-menu.mega-menu,
	.header .menu > ul > li .sub-menu{
		visibility: visible;
		opacity: 1;
		position: absolute;
		box-shadow: none;
		margin:0;
		padding:15px;
		font-weight: bold;
		top:0;
		left:0;
		width: 100%;
		height: 100%;
		padding-top: 65px;
		max-width: none;
		min-width: auto;
		display: none;
		transform: translateX(0%);
		overflow-y: auto;
	}
.header .menu > ul > li .sub-menu.active{
	display: block;
}
@keyframes slideLeft{
	0%{
		opacity:0;
		transform: translateX(100%);
	}
	100%{
	opacity:1;
	transform: translateX(0%);
	}
}
@keyframes slideRight{
	0%{
		opacity:1;
		transform: translateX(0%);
	}
	100%{
	opacity:0;
	transform: translateX(100%);
	}
}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item img{
		margin-top:0;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center .title{
		margin-bottom: 20px;
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item.text-center:last-child .title{
		margin-bottom:0px;
	}
	.header .menu > ul > li .sub-menu.mega-menu-column-4 > .list-item{
		flex: 0 0 100%;
        padding: 0px;
	}
	.header .menu > ul > li .sub-menu > ul > li > a,
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul > li > a{
		display: block;
	}
	.header .menu > ul > li .sub-menu.mega-menu > .list-item > ul {
		margin-bottom: 15px;
	}
	.menu-overlay{
		position: fixed;
		background-color: rgba(0,0,0,0.5);
		left:0;
		top:0;
		width: 100%;
		height: 100%;
		z-index: 1098;
		visibility: hidden;
		opacity:0;
		transition: all 0.5s ease;
	}
	.menu-overlay.active{
	visibility: visible;
	opacity:1;
	}
}
</style>