<template>
<div>
    <NavBar/> 
		<router-view ></router-view>
    <Footer/>
    <Popup></Popup>
</div>
</template>

<script>

//import Snowf from 'vue-snowf';

import Home from './views/Home';
import NavBar from './components/Nav-bar.vue';
import Footer from './components/Footer.vue';
import Popup from './components/Political/Pop-up.vue';

//import Snow from './js/snow.js';


export default {
  name: 'App',
  components: {
    //Snowf,
    Home,
    NavBar,
    Footer,
    Popup
},

  data: () => ({
    //
  }),
};
</script>