<template>
  <div v-if="!cookieAccepted" class="popup">
    <div class="popup-content">
      <div class="popup-header">
        <h2>Çerez Politikası</h2>
      </div>
      <p>
        Bu internet sitesini kullanmaya devam ettiğinizde yalnızca teknik çerezler
        yüklenecektir. Daha fazla bilgi için lütfen
        <a class="popup-link" href="Kuantek_KVKK_Aydınlatma_Metni.pdf" target="_blank">KVK Aydınlatma Metni</a>,
        <a class="popup-link" href="Kuantek_Gizlilik_Cerez_Politikası.pdf" target="_blank">Gizlilik ve Çerez Politikası</a>
        bakın.
      </p>
      <div class="popup-footer">
        <button class="close-btn" @click="closePopup">Kapat</button>
        <button class="accept-btn" @click="acceptCookies">Kabul Et</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cookieAccepted: localStorage.getItem('cookieAccepted') === 'true',
    };
  },
  methods: {
    closePopup() {
      this.cookieAccepted = true; // Popup kapatıldığında çerez kabul edilmiş sayalım.
    },
    acceptCookies() {
      this.cookieAccepted = true;
      localStorage.setItem('cookieAccepted', 'true');
      // Çerez ayarlarını kontrol etmek için ilgili işlemleri yapabilirsiniz.
    },
  },
  mounted() {
    // Sayfa yüklendiğinde pop-up'ı göster
    if (!this.cookieAccepted) {
      // Kullanıcı daha önce çerezleri kabul etmemişse pop-up'ı göster.
    }
  },
};

</script>

<style scoped>
.popup {
  position: fixed;
  bottom: 0px;
  right: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 20px;
}

.popup-content {
  text-align: center;
  color: white;
  width: 100%;
}

.popup-header {
  text-align: center;
  margin-bottom: 15px;
}

.popup-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.popup-link {
  text-decoration: underline;
}

.close-btn,
.accept-btn {
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.close-btn {
  background-color: #ccc;
  margin-right: 10px;
}

.accept-btn {
  background-color: #ff0000;
  color: #fff;
}
</style>