import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/contact/',
    component: () => import( '../components/Contact/ContactForm.vue')
  },
  {
    path: '/training/',
    component: () => import( '../components/Training/TrainingService.vue')
  },
  {
    path: '/jesd204b-ip-core/',
    component: () => import( '../components/IP-Core/JESD204B.vue')
  },
  {
    path: '/can-canfd-ip-core/',
    component: () => import( '../components/IP-Core/CanCanFdIpCore.vue')
  },
  {
    path: '/ethernet-ip-core/',
    component: () => import( '../components/IP-Core/EthernetIpCore.vue')
  },
  {
    path: '/surround-vision-system/',
    component: () => import( '../components/IP-Core/SurroundVisionSystem.vue')
  },
  {
    path: '/thermal-vision-isp/',
    component: () => import( '../components/IP-Core/ThermalVisionIsp.vue')
  },
  {
    path: '/day-vision-isp/',
    component: () => import( '../components/IP-Core/DayVisionIsp.vue')
  },
  {
    path: '/Nyks+KFM-E-T120SoM/',
    component: () => import( '../components/KNT_SoM/NyksKFMET120SoM.vue')
  },
  {
    path: '/Nyks+KFM-E-T20SoM/',
    component: () => import( '../components/KNT_SoM/NyksKFMET20SoM.vue')
  },
  {
    path: '/Nyks+KFM-E-Ti35SoM/',
    component: () => import( '../components/KNT_SoM/NyksKFMETi35SoM.vue')
  },
  {
    path: '/TriPi-FPGA-Development-Board/',
    component: () => import( '../components/KNT_SoM/TriPi.vue')
  },
  {
    path: '/LWIR-Camera-Core/',
    component: () => import( '../components/KNT_SoM/LWIRCameraCore.vue')
  },
  {
    path: '/titanium-ti60-dev/',
    component: () => import( '../components/Efinix/TitaniumTi60F225DevelopmentKit.vue')
  },
  {
    path: '/trion-t120-bga576/',
    component: () => import( '../components/Efinix/TrionT120BGA576DevelopmentKit.vue')
  },
  {
    path: '/trion-t120-bga324/',
    component: () => import( '../components/Efinix/TrionT120BGA324DevelopmentKit.vue')
  },
  {
    path: '/trion-t120-bga256/',
    component: () => import( '../components/Efinix/TrionT20BGA256DevelopmentKit.vue')
  },
  {
    path: '/trion-t20/',
    component: () => import( '../components/Efinix/TrionT20MIPIDevelopmentKit.vue')
  },
  {
    path: '/trion-t8/',
    component: () => import( '../components/Efinix/TrionT8DevelopmentKit.vue')
  },
  {
    path: '/xyloni/',
    component: () => import( '../components/Efinix/XyloniDevelopmentKit.vue')
  },
  {
    path: '/about/',
    component: () => import( '../components/About/About.vue')
  },
  {
    path: '/our-team/',
    component: () => import( '../components/OurTeam/OurTeam.vue')
  },
  {
    path: '/blog/',
    component: () => import( '../components/Blog/Blog.vue')
  },
  {
    path: '/news/',
    component: () => import( '../components/News/News.vue')
  },
  {
    path: '/document/',
    component: () => import( '../components/Document/Document.vue')
  },
  {
    path: '/link',
    component: () => import( '../views/link.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
