<template>
  <v-app>
    <HomeSection></HomeSection>
    <CardSection></CardSection>
    <KatalogSection></KatalogSection>
    <Distributorships></Distributorships>
    <AboutDescription></AboutDescription>
  </v-app>
</template>

<script>
import HomeSection from '../components/HomeSection';
import CardSection from '../components/CardSection';
import KatalogSection from '../components/KatalogSection';
import Distributorships from '../components/Distributorships';
import AboutDescription from '../components/AboutSection';

export default {
  name: 'App',

  components: {
    HomeSection,
    CardSection,
    KatalogSection,
    Distributorships,
    AboutDescription,
  },

  data: () => ({
    //
  }),
};
</script>


<style >
v-app{
  z-index: 0;
}
</style>